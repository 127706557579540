import { createContext, useState } from "react";

export const notifyContext = createContext();
export function NotifyProvider({children}) {
    const [notifies, setNotifies] = useState([]);
    const value = {
        notifies,
        setNotifies
    };
    return (
        <notifyContext.Provider value={value}>
            {children}
        </notifyContext.Provider>
    )
}