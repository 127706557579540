import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Dashboards = Loadable(lazy(() => import('src/views/admin/dashboards/Dashboards')));
const HomePage = Loadable(lazy(() => import('src/views/home-page/Home')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));
const Login = Loadable(lazy(() => import('../views/Login/Login')));
const Register = Loadable(lazy(() => import('../views/Register/Register')));
const Profile = Loadable(lazy(() => import('../views/Profile/AccountSetting')));
const TransferCoin = Loadable(lazy(() => import('../views/transaction/TransferCoin')));
// ConfigAccount
const ConfigAccountFb = Loadable(lazy(() => import('../views/ConfigAccount/facebook/ConfigAccountFb')));
// Earn coin
const CrossEmotionsPost = Loadable(lazy(() => import('../views/DoJob/facebook/CrossEmotionsPost')));
const CrossLikePage = Loadable(lazy(() => import('../views/DoJob/facebook/CrossLikePage')));
const CrossComment = Loadable(lazy(() => import('../views/DoJob/facebook/CrossComment')));
const CrossCmtEmotions = Loadable(lazy(() => import('../views/DoJob/facebook/CrossEmotionsComment')));
const CrossShare = Loadable(lazy(() => import('../views/DoJob/facebook/CrossShare')));
const CrossFollowing = Loadable(lazy(() => import('../views/DoJob/facebook/CrossFollowing')));
const CrossJoinGroup = Loadable(lazy(() => import('../views/DoJob/facebook/CrossJoinGroup')));
// Increase interaction
const EmotionsPost = Loadable(lazy(() => import('../views/CreateJob/facebook/EmotionsPost')));
const EmotionsComment = Loadable(lazy(() => import('../views/CreateJob/facebook/EmotionsComment')));
const LikePage = Loadable(lazy(() => import('../views/CreateJob/facebook/LikePage')));
const JoinGroup = Loadable(lazy(() => import('../views/CreateJob/facebook/JoinGroup')));
const CommentPost = Loadable(lazy(() => import('../views/CreateJob/facebook/CommentPost')));
const Share = Loadable(lazy(() => import('../views/CreateJob/facebook/Share')));
const Following = Loadable(lazy(() => import('../views/CreateJob/facebook/Following')));

const ManageUser = Loadable(lazy(() => import('../views/admin/user/ManageUser')));
const ManageNotify = Loadable(lazy(() => import('../views/admin/notify/ManageNotify')));
const ListTransaction = Loadable(lazy(() => import('../views/admin/transaction/List')));
const ListTransacitonUser = Loadable(lazy(() => import('../views/transaction/List')));
const FacebookJobsAdmin = Loadable(lazy(() => import('../views/admin/manage-jobs/Facebook')));
const UserDoFbJobAdmin = Loadable(lazy(() => import('../views/admin/manage-user-do-jobs/Facebook')));
const FbAccountAdmin = Loadable(lazy(() => import('../views/admin/account-social/Facebook')));
const ConfigJobs = Loadable(lazy(() => import('../views/admin/config-jobs/List')));
const Permission = Loadable(lazy(() => import('../views/admin/permission/Permission')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/home" /> },
      { path: '/home', exact: true, element: <HomePage /> },
      { path: '/profile', exact: true, element: <Profile /> },
      { path: '/transfer', exact: true, element: <TransferCoin /> },
      { path: '/user-transaction', exact: true, element: <ListTransacitonUser />},
      // ConfigAccount
      { path: '/config-fb-account', exact: true, element: <ConfigAccountFb />},
      // Earn coin  
      { path: '/cross-emotions-post', exact: true, element: <CrossEmotionsPost />},
      { path: '/cross-like-page', exact: true, element: <CrossLikePage />},
      { path: '/cross-comment', exact: true, element: <CrossComment />},
      { path: '/cross-emotions-cmt', exact: true, element: <CrossCmtEmotions />},
      { path: '/cross-share', exact: true, element: <CrossShare />},
      { path: '/cross-following', exact: true, element: <CrossFollowing />},
      { path: '/cross-join-group', exact: true, element: <CrossJoinGroup />},
      // Increase interaction
      { path: '/emotions-post', exact: true, element: <EmotionsPost />},
      { path: '/emotions-comment', exact: true, element: <EmotionsComment />},
      { path: '/like-page', exact: true, element: <LikePage />},
      { path: '/join-group', exact: true, element: <JoinGroup />},
      { path: '/comment-post', exact: true, element: <CommentPost />},
      { path: '/share', exact: true, element: <Share />},
      { path: '/following', exact: true, element: <Following />},
      
      { path: '/admin/dashboards', exact: true, element: <Dashboards /> },
      { path: '/admin/manage-user', exact: true, element: <ManageUser />},
      { path: '/admin/manage-notify', exact: true, element: <ManageNotify />},
      { path: '/admin/transactions', exact: true, element: <ListTransaction />},
      { path: '/admin/facebook-jobs', exact: true, element: <FacebookJobsAdmin />},
      { path: '/admin/do-fb-jobs', exact: true, element: <UserDoFbJobAdmin />},
      { path: '/admin/accounts-fb', exact: true, element: <FbAccountAdmin />},
      { path: '/admin/config-jobs', exact: true, element: <ConfigJobs />},
      { path: '/admin/permission', exact: true, element: <Permission />},
      { path: '*', element: <Navigate to="/home" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '/dang-nhap', element: <Login /> },
      { path: '/dang-ky', element: <Register /> },
    ],
  },
];

export default Router;
