import { useEffect, ReactElement, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authContext from 'src/contexts/authContext';

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {userData} = useContext(authContext);
  const paths = [
    '/dang-nhap',
    '/dang-ky',
    '/home',
    '/',
    '/profile',
    '/auth/maintenance'
  ]

  useEffect(() => {
    if(Object.keys(userData).length !== 0) {
      const isCustomer = userData?.roles?.some((role) => role.name === 'customer');
      if(!paths.includes(pathname)) {
        if((pathname.startsWith('/admin') && isCustomer) || (!pathname.startsWith('/admin') && !isCustomer)){
          navigate('/auth/404');
          return;
        }
      }
    };
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname, userData]);

  return children || null;
}
