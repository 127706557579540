import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/Store';
import Spinner from './views/spinner/Spinner';
import './utils/i18n';
import { NotificationProvider } from './contexts/notificationContext';
import { AuthContextProvider } from './contexts/authContext';
import { NotifyProvider } from './contexts/notifyContext';
import { LanguageProvider } from './contexts/languageContext';
import { MaintenanceProvider } from './contexts/maintenanceContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <LanguageProvider>
          <MaintenanceProvider>
            <AuthContextProvider>
              <NotificationProvider>
                <NotifyProvider>
                  <App />
                </NotifyProvider>
              </NotificationProvider>
            </AuthContextProvider>
          </MaintenanceProvider>
        </LanguageProvider>
      </BrowserRouter>
    </Suspense>
  </Provider>,
);
