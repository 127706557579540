import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { useEffect, useContext } from 'react';
import authContext from './contexts/authContext';
import { myInfo, setSessionLanguage } from './api/userApi';
import languageContext from './contexts/languageContext';
import { setDarkMode, toggleSidebar } from './store/customizer/CustomizerSlice';
import { checkMaintenance } from './api/adminApi';
import maintenanceContext from './contexts/maintenanceContext';

function App() {
  const {setUserData} = useContext(authContext);
  const {setIsMaintenance} = useContext(maintenanceContext);
  const {pathname} = useLocation();
  const {language} = useContext(languageContext);
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.customizer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSideBarMini = useMediaQuery(`(max-width: 1350px)`);
  
  const handleGetInfo = async () => {
    try{
      const res = await myInfo();
      if(res) {
        setUserData(res?.data);
      }
    }catch(err) {
      localStorage.removeItem('access_token');
      navigate('/dang-nhap');
    }
  }

  const handleSetSessionLang = async (language) => {
    try{
      const response = await setSessionLanguage(language);
    }catch(err) {
      console.log(err);
    }
  }
  const handleCheckMaintenanceMode = async () => {
    try{
      const response = await checkMaintenance();
      setIsMaintenance(response.data);
    }catch(err) {
      console.log(err);
    }
  }
  const pathNoCall = [
    '/dang-nhap',
    '/dang-ky',
    '/auth/maintenance',
    '/auth/404'
  ];

  useEffect(() => {
    if(localStorage.getItem('language')) handleSetSessionLang(language);
  }, [language])

  useEffect(() => {
    handleCheckMaintenanceMode();

    if(localStorage.getItem('theme')) dispatch(setDarkMode(localStorage.getItem('theme')));
    
    if(!localStorage.getItem('access_token')){
      navigate('/dang-nhap');
      return;
    }
    
  }, [])
  useEffect(() => {
    if(pathNoCall.includes(pathname)) return;
    handleGetInfo();
  }, [pathname]);

  useEffect(() => {
    const a = customizer.isCollapse || (customizer.isCollapse === false && isSideBarMini);
    dispatch(toggleSidebar(a));
  }, [isSideBarMini])
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
  );
}

export default App;
