import { sendPost, sendGet, sendDelete, sendPut } from "./axiosClient";

export const getUsers  = (data) => sendGet('/admin/account', data);
export const updateInfoUser  = (id, data) => sendPost(`/admin/account/update-info/${id}`, data);
export const updateCoinUser  = (id, data) => sendPost(`/admin/account/update-coin/${id}`, data);
export const updateStatusUser  = (id, data) => sendPost(`/admin/account/update-status/${id}`, data);

export const getListNotify  = (data) => sendGet(`/admin/notify`, data);
export const createNotify  = (data) => sendPost(`/admin/notify`, data);
export const updateNotify  = (id, data) => sendPost(`/admin/notify/${id}`, data);

export const getListTransaction  = (data) => sendGet(`/admin/transaction`, data);

export const getListFbJobs  = (data) => sendGet(`/admin/jobs/fb`, data);
export const stopFbJob  = (data) => sendPost(`/admin/jobs/fb/stop`, data);
export const getUserDoneJob  = (data) => sendGet(`/admin/jobs/fb/done-job`, data);

export const getListFbAccount  = (data) => sendGet(`/admin/account/fb`, data);
export const revokeCoin  = (data) => sendPost(`/admin/account/fb/revoke-coin`, data);

export const newConfigJob  = (data) => sendPost(`/admin/config-job`, data);
export const listConfigJob  = (data) => sendGet(`/admin/config-job`, data);
export const updateConfigJob  = (data) => sendPut(`/admin/config-job`, data);

export const getStatistics  = (data) => sendGet(`/admin/statistics`, data);
export const getTopFarm  = (data) => sendGet(`/admin/statistics/top-farm`, data);
export const getTopSpend  = (data) => sendGet(`/admin/statistics/top-spend`, data);
export const getNearExpireJobsFb  = (data) => sendGet(`/admin/jobs/fb/near-expire`, data);
export const updatePriority  = (data) => sendPost(`/admin/jobs/fb/prioritize`, data);

export const getListAdmin  = (data) => sendGet(`/admin/account/list-admin`, data);
export const createAdmin  = (data) => sendPost(`/admin/account/create-admin`, data);
export const updateAdmin  = (id, data) => sendPost(`/admin/account/update-admin/${id}`, data);
export const deleteAdmin  = (id) => sendPost(`/admin/account/delete-admin/${id}`);

export const getPermissions  = (data) => sendGet(`/admin/permissions`, data);
export const createPermission  = (data) => sendPost(`/admin/permissions`, data);
export const updatePermission  = (id, data) => sendPost(`/admin/permissions/update/${id}`, data);
export const deletePermission  = (id) => sendPost(`/admin/permissions/delete/${id}`);

export const getRoles  = (data) => sendGet(`/admin/roles`, data);
export const createRole  = (data) => sendPost(`/admin/roles`, data);
export const updateRole  = (id, data) => sendPost(`/admin/roles/update/${id}`, data);
export const deleteRole  = (id) => sendPost(`/admin/roles/delete/${id}`);

export const checkMaintenance = (data) => sendGet(`/admin/maintenance`, data);
export const toggleMaintenance = (data) => sendPost(`/admin/maintenance`, data);