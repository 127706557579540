import { createContext, useState } from 'react';

const maintenanceContext = createContext();

export const MaintenanceProvider = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const value = {
    isMaintenance,
    setIsMaintenance
};
  return (
    <maintenanceContext.Provider value={value}>
      {children}
    </maintenanceContext.Provider>
  );
};

export default maintenanceContext;

