import React, { createContext, useState, useEffect, useMemo } from "react";

const authContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(
    localStorage.getItem("access_token") || ""
  );
  const [userData, setUserData] = useState({});
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [asideStatus, setAsideStatus] = useState(false);
  useEffect(() => {
    localStorage.setItem("access_token", token);
  }, [token]);
  const value = useMemo(
    () => ({
      asideStatus,
      setAsideStatus,
      token,
      setToken,
      userData,
      setUserData,
      openMenuMobile,
      setOpenMenuMobile,
    }),
    [token, userData, asideStatus, openMenuMobile]
  );
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};
export default authContext;
