import { createContext, useState } from 'react';

const languageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language'));
  const value = {
    language,
    setLanguage
};
  return (
    <languageContext.Provider value={value}>
      {children}
    </languageContext.Provider>
  );
};

export default languageContext;