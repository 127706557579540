import { sendPost, sendGet, sendDelete } from "./axiosClient";

export const myInfo  = (data) => sendGet('/user/account/me', data);
export const uploadAvatar = (data) => sendPost('/user/account/avatar/', data);
export const changePassword = (data) => sendPost('/user/account/change-password', data);
export const updateInfo = (data) => sendPost('/user/account/update', data);
export const updateTwoFactorAuth = (data) => sendPost('/user/account/update-two-factor', data);

export const getListNotify = (data) => sendGet('/user/notify', data);
export const readNotify = (data) => sendPost('/user/notify/mark-as-read', data);
export const readAllNotify = (data) => sendPost('/user/notify/mark-all-as-read', data);
export const getAppNotification = (data) => sendGet('/user/notify/app', data);

export const transferCoin = (data) => sendPost('/user/transaction/transfer', data);
export const getListTransaction = (data) => sendGet('/user/transaction', data);

export const storeFbAccount = (data) => sendPost('/user/fb-account', data);
export const getFbAccount = (data) => sendGet('/user/fb-account', data);
export const activeAccountFb = (data) => sendPost('/user/fb-account/active', data);
export const deleteAccountFb = (data) => sendPost('/user/fb-account/delete', data);

export const createJob = (data) => sendPost('/user/job/create', data);
export const getJob = (data) => sendPost('/user/job/get', data);
export const updateJob = (data) => sendPost('/user/job/update', data);
export const getCreatedJobs = (data) => sendGet('/user/job/created', data);
export const reportJob = (data) => sendPost('/user/job/report', data);
export const getSeedingTypes = (data) => sendGet('/user/seeding-types', data);

export const setSessionLanguage = (data) => sendGet(`/user/set-language/${data}`);
export const checkLiveAccFb = (data) => sendGet(`/user/fb-account/check`, data);
