import { Alert, AlertTitle, Snackbar, IconButton, Stack } from '@mui/material';
import { IconX } from '@tabler/icons';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { TaskAlt, ErrorOutline } from '@mui/icons-material';
import { useSelector } from 'react-redux';

export const notificationContext = createContext();
export const useNotification = () => useContext(notificationContext);
const Notification = ({ open, handleClose, color, info, title, theme }) => {
  const background =
    theme === 'light'
      ? color === 'success'
        ? '#13deb9'
        : color === 'error'
        ? '#fa896b'
        : color === 'report'
        ? '#539bff'
        : 'gray'
      : color === 'success'
      ? '#02b3a9'
      : color === 'error'
      ? '#f3704d'
      : color === 'report'
      ? '#1682d4'
      : 'gray';
  useEffect(() => {
    setTimeout(() => handleClose(), 5000);
  }, []);
  return (
    <>
      <div style={!open ? { display: 'none' } : {}}>
        <div
          style={{
            display: 'flex',
            top: '20px',
            right: '20px',
            position: 'fixed',
            zIndex: 100,
            minWidth: '200px',
            minHeight: '40px',
            backgroundColor: background,
            color: 'white',
            borderRadius: '5px',
            padding: '10px',
            fontFamily: 'Inter, sans-serif',
          }}
        >
          {color === 'success' ? (
            <TaskAlt style={{ marginRight: '10px' }} width={20} height={20} />
          ) : color === 'error' ? (
            <ErrorOutline style={{ marginRight: '10px' }} width={20} height={20} />
          ) : (
            <></>
          )}
          <div style={{ flex: 3 }}>
            <h3 style={{ margin: '0px 0 7px 0' }}>{title}</h3>
            <p style={{ fontSize: '15px', margin: '0px' }}>{info}</p>
          </div>
          <div>
            <IconButton
              onClick={handleClose}
              style={{ width: '25px', height: '25px', padding: '0px' }}
            >
              <IconX color="white" width={20} height={20}></IconX>
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};
export const NotificationProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('success');
  const [info, setInfo] = useState('Notification');
  const [title, setTitle] = useState('Notification');
  const customizer = useSelector((state) => state.customizer);

  const show = (title, msg, color) => {
    setTitle(title);
    setInfo(msg);
    setColor(color);
    setLoading(true);
  };
  const hide = () => {
    setLoading(false);
  };
  return (
    <notificationContext.Provider
      value={{
        loading: loading,
        show: show,
        hide: hide,
      }}
    >
      {loading && (
        <Notification
          open={loading}
          handleClose={hide}
          info={info}
          color={color}
          title={title}
          theme={customizer.activeMode}
        />
      )}
      {props.children}
    </notificationContext.Provider>
  );
};
